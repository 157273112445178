.dpd-card {
  width: 834px;

  .card-header {
    // display: flex;
    background-color: #fff;
    padding: 24px;
    color: #414042;
    letter-spacing: normal;
    border-bottom-color: #ccc;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    float: left;
    width: 100%;

    .back-button {
      cursor: pointer;
      background-image: url("../../../../assets/images/backArrow.svg");
      background-repeat: no-repeat;
      height: 24px;
      width: 24px;
      background-size: 24px 23px;
      margin-right: 8px;
      float: left;
    }

    .location {
      margin: 4px 0 0;
    }
  }

  .card-body-wrapper {
    display: flex;
    justify-content: center;

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 568px;
      padding: 24px;

      @media (min-width: 992px) {
        align-items: center;
      }
    }
  }

  // overrides default bootstrap styles
  .card-title {
    color: #1a1a1a;
    letter-spacing: normal;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
  }

  .card-text {
    color: #1a1a1a;
    letter-spacing: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .card-footer {
    display: flex;
    justify-content: flex-end;
    border: none;
    background-color: #fff;
    width: 100%;
    padding-right: 0;
  }
}
