header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  background: $secondary;
  padding: 0;

  @include media-breakpoint-up(lg) {
    padding: 8px 0;
    position: relative;
  }

  a {
    float: left;
    color: $white;
    text-decoration: none;
    font-weight: map-get($font-weight, "medium");
  }

  .container {
    padding: 0;
  }

  .navbar {
    padding: 0;
    align-items: baseline;

    &-toggler {
      float: right;
      padding: 12px;
      border: 0;

      @include media-breakpoint-up(lg) {
        margin: 0 15px 0 0;
      }

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    &-collapse {
      background: $black;
      float: left;
      width: 100%;

      @include media-breakpoint-up(lg) {
        background: transparent;
        float: right;
        width: auto;
      }
    }
  }

  .logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    padding: 3px 15px;

    @include media-breakpoint-up(lg) {
      padding: 3px 0;
    }

    img {
      width: 46px;

      @include media-breakpoint-up(lg) {
        width: 89px;
      }
    }
  }

  .navbar-nav .nav-link {
    color: $white;
    text-align: center;
    padding: 13px 0;
    font-family: map-get($font, "inter");
    width: 100%;
    letter-spacing: 0.6px;

    @include media-breakpoint-up(lg) {
      padding: 0 12px !important;
      width: auto;
    }

    &:first-of-type {
      padding-top: 28px;

      @include media-breakpoint-up(lg) {
        padding-top: 0;
      }
    }

    &:last-of-type {
      padding-bottom: 28px;

      @include media-breakpoint-up(lg) {
        padding-right: 0;
        padding-bottom: 0;
      }
    }

    &:hover,
    &:focus {
      color: $white;

      span {
        border-bottom: 1px solid $white;
      }
    }

    &.active {
      span {
        border-bottom: 1px solid $white;
      }
    }

    img {
      margin: -3px 0 0;
    }
  }

  .cursor-none {
    cursor: default;
  }
}
