.connect {
  .hero-section {
    background: url("../../assets/images/banner2.jpg") no-repeat;
    background-size: cover;
    background-position: center 20px;

    @media only screen and (min-width: 992px) {
      background-position: center bottom;
    }
  }

  .content-apply {
    .plcholder {
      background: url("../../assets/images/choice-form.png") no-repeat;
      background-size: cover;
      background-position: 70% top;

      @media only screen and (min-width: 992px) {
        right: 0;
      }
    }

    .gutter {
      padding: 0 17px;

      @media only screen and (min-width: 992px) {
        padding: 0 48px 0 0;
      }
    }
  }
}
