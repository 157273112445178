.banner {
  background: url("../../assets/images/banner1.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 44.77px;
  float: left;
  width: 100%;
  height: calc(100% - 45px);

  @include media-breakpoint-up(lg) {
    height: calc(100% - 60px);
    margin: 0;
  }

  h2 {
    line-height: 1.23;
    font-size: 28px;

    @include media-breakpoint-up(sm) {
      font-size: 45px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 64px;
    }

    &::after {
      content: "";
      display: block;
      height: 5px;
      width: 200px;
      background-color: $tertiary;
      position: relative;
      left: 0;
      bottom: -2px;
      margin: 0 auto;

      @include media-breakpoint-up(sm) {
        bottom: -8px;
      }

      @include media-breakpoint-up(lg) {
        bottom: -31px;
        margin: 0;
      }
    }
  }

  h5 {
    font-size: 16px;
    font-weight: 300;
    text-transform: none;
    margin: 10px 0 0 0;
    line-height: 1.4;

    @include media-breakpoint-up(sm) {
      font-size: 21px;
      margin: 25px 0 0 0;
    }

    @include media-breakpoint-up(lg) {
      font-size: 28px;
      margin: 71px 0 0 0;
    }
  }

  &.banner2 {
    background: none;
  }

  &.banner3 {
    background: none;
  }

  .outer-container {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.38);
    position: relative;

    @include media-breakpoint-between(sm, lg) {
      max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
      background-color: transparent;
    }
  }

  .content {
    color: $white;
    float: left;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  h2 {
    margin: 0;
  }

  .btn-scroll {
    position: absolute;
    left: calc(50% - 24px);
    bottom: 40px;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      bottom: 40px;
    }
  }
}

// @keyframes animatedBackground {
//   from {
//     background-position: calc(100% - -950px) calc(100% - 20px);
//   }
//   to {
//     background-position: right calc(100% - 20px);
//   }
// }

.bg-tertiary {
  background: $tertiary;
}
