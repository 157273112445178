.hexagon-content {
  padding: 64px 0;

  .row-container {
    display: flex;
    align-items: center;
    padding: 0 0 64px;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      padding: 0 0 76px;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    &.left {
      .content-container {
        @include media-breakpoint-up(lg) {
          margin-left: 107px;
        }
      }

      .image-container {
        order: 1;

        @include media-breakpoint-up(lg) {
          order: 0;
        }

        img {
          margin-right: 0;

          @include media-breakpoint-up(lg) {
            margin-left: 53px;
          }
        }
      }
    }
  }

  h1 {
    color: $black !important;
  }

  hr {
    height: 4px;
    opacity: 1;
    border: 0;
    width: 160px;
    margin: 14px 0 23px 2px;

    @include media-breakpoint-up(lg) {
      margin: 11px 0 23px 2px;
    }
  }

  ul {
    padding-left: 25px;
    margin-bottom: 20px;
  }

  li {
    font-family: map-get($font, "inter");
    font-size: 16px;
    color: $secondary;
    line-height: 1.5;
    margin: 0 0 19px;
  }

  p {
    font-family: map-get($font, "inter");
    font-size: 16px;
    color: $secondary;
    line-height: 1.5;
    margin-left: 2px;
    margin-bottom: 23px;
  }

  .image-container img {
    margin: 64px 0 0;
    width: 89%;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  .btn {
    margin: 0 4px;
    padding: 11px 7px;

    @media (min-width: 360px) {
      padding: 11px 9px;
    }

    @include media-breakpoint-up(lg) {
      padding: 11px 30px;
    }

    &:first-of-type {
      margin: 0;

      @include media-breakpoint-up(lg) {
        margin-left: 2px;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .content-container {
    margin: 0;

    @include media-breakpoint-up(lg) {
      margin-top: 17px;
      margin-left: 53px;
    }
  }
}
